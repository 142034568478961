var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4 white rounded-lg elevation-0"
  }, [_c('v-row', {
    staticClass: "px-4 pt-7"
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.TaskIds).items.value,
      "item-text": "id",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.TaskIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.TaskIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.TaskIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.TaskIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.TaskIds).value"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Title).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Title).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Title), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Title).value"
    }
  })], 1), _c('v-col', [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.DueToFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.DueToFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.DueToFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.DueToFrom).value"
    }
  }), _c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.DueToTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.DueToTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.DueToTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.DueToTo).value"
    }
  })], 1), _c('v-col', [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ResubmissionFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ResubmissionFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ResubmissionFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ResubmissionFrom).value"
    }
  }), _c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ResubmissionTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ResubmissionTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ResubmissionTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ResubmissionTo).value"
    }
  })], 1), _c('v-col', [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.SolvedFrom).value"
    }
  }), _c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.SolvedTo).value"
    }
  })], 1), _c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedBy).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedBy).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedBy).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedBy).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SolvedBy), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.SolvedBy).value"
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignedTo).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignedTo).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignedTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignedTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignedTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.AssignedTo).value"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Type).value"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }